.vtw-message {
  display: block;
  text-align: center;
  line-height: 24px;
  padding: 20px;
  width: 430px;
  margin: 0 auto;

  &.vtw-icon {
    position: relative;
    padding-left: 30px;

    &:before {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: 20px;
      margin-left: -30px;
      width: 20px;
      height: 20px;
    }

    &.vtw-error:before {
      background-image: url(../../images/Incorrect_Red.svg);
    }
  }
}

@media screen and (min-width: 0) and (max-width: 450px) {
  .vtw-message {
    width: 100%;
  }
}

.error-message {
  .component-inner {
    margin-top: 50px;
  }
}

.rail-error-container {
  padding-top: 30px;
  background-color: rgba(100, 100, 100, 0.1);
  display: flex;
  flex-direction: column;

  .error-icon-rails {
    background-image: url('../../images/Error.svg');
    height: 48px;
    width: 48px;
    background-repeat: no-repeat;
    margin-left: calc(50% - 24px);
  }

  .error-msg-rails {
    font-size: 28px;
    line-height: 34px;
    vertical-align: super;
    width: 100%;
    text-align: center;
    margin: calc(17px / 2) 0;
  }

  .btn-group.actions .btn.gradient-btn {
    margin: 0 auto 20px;
  }
}

.error-container {
  .error-logo {
    padding: 40px;

    img {
      width: 48px;
      height: 48px;
    }
  }

  .error-message {
    padding: 0 10px 40px 10px;
    font-family: $font-regular;
    color: $color-grey-placeholder;
    font-size: 22px;
  }

  .error-button {
    a {
      margin: auto 0;
    }
  }
}
